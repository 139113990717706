#create-bg {
    background-image: url('../assets/create-layer.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
}

#bets-container {
    display: flex;
}

#bets-container .bet-container {
    display: flex;
    margin: 5px 0;
}

@media screen and (max-width: 767px) {

    #head-bg {
        display: flex;
        justify-content: start;
        height: 370px;
    }

    #create-join-games {
        display: none;
    }

    #create-page {
        display: flex;
        margin-top: 230px;
    }
}

@media screen and (max-width: 520px) {

    #head-bg {
        display: flex;
        justify-content: start;
        height: 350px;
    }

    #create-join-games {
        display: none;
    }

    #create-page {
        display: flex;
        margin-top: 200px;
    }

    #create-page h3 {
        display: flex;
        justify-content: center;
        font-size: 1.5rem;
    }

    #bets-container {
        display: flex;
        flex-direction: column;
    }

    #bets-container .bet-container {
        display: flex;
        margin: 5px 0;
    }
}

@media screen and (max-width: 399px) {

    #create-page h3 {
        display: flex;
        font-size: 1rem;
    }
}