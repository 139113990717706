@media screen and (max-width: 767px) {

    #head-bg {
        display: flex;
        justify-content: start;
        height: 370px;
    }

    #create-join-games {
        display: none;
    }

    #my-matches {
        display: flex;
        margin-top: 230px;
    }
}

@media screen and (max-width: 520px) {

    #head-bg {
        display: flex;
        justify-content: start;
        height: 350px;
    }

    #create-join-games {
        display: none;
    }

    #create-page {
        display: flex;
        margin-top: 200px;
    }

    #create-page h3 {
        display: flex;
        justify-content: center;
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 399px) {

    #create-page h3 {
        display: flex;
        font-size: 1rem;
    }
}