@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  html,
  body {
    padding: 0;
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    display: block;
  }

  #head-bg {
    background-image: url('../src/assets/main-bg-2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 100%;
    min-height: 100%;
    height: 844px;
    /* background-size: cover;
    min-width: 100%;
    height: 100%;
    resize: both; */
  }

  #about-bg {
    background-image: url('../src/assets/main-about.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
  }

  @media screen and (max-width: 767px) {

    #head-bg {
      display: flex;
      justify-content: start;
      height: 545px;
    }

    #left-menu,
    #right-menu {
      display: none;
    }

    #create-join-games {
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 150px;
    }

    #create-join-btn {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    #create-join-btn button {
      display: flex;
      justify-content: center;
      margin: 10px 0;
      width: 100%;
    }

    #about-container {
      margin-top: 100px;
    }

    #fifa-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 100px;
    }

    #fifa-section p {
      text-align: center;
    }

    #street-section {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin-top: 100px;
    }

    #street-section p {
      text-align: center;
    }

    #madden-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 100px;
    }

    #madden-section p {
      text-align: center;
    }

    #footer-container {
      margin-top: 100px;
    }

    #footer-top {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }

    #footer-menu {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    #footer-menu>a {
      margin: 5px 0;
    }

    #hr-footer {
      display: none;
    }

    #footer-bottom {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 10px;
    }

    #footer-bottom span {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 376px) {

    #head-bg {
      display: flex;
      justify-content: start;
      height: 545px;
    }

    #left-menu,
    #right-menu {
      display: none;
    }

    #create-join-games {
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 120px;
    }

    #create-join-games h3 {
      font-size: larger;
      text-align: center;
    }

    #create-join-btn {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    #create-join-btn button {
      display: flex;
      justify-content: center;
      margin: 10px 0;
      width: 100%;
    }

    #about-container {
      margin-top: 100px;
    }
  }
}

@layer components {

  /* glass-morphism */

}